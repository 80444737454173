export const cloudAccelerationText = `一、活动说明
1、云加速(专业版)通用流量包25元:25元/月，每月可获得4GB国内通用流量+云加速(专业版)权益+2款热门权益(会员月卡:爱奇艺视频会员月卡;优酷视频会员月卡;腾讯视频会员月卡;芒果TV双端影视月卡;QQ音乐绿钻月卡;网易云音乐会员月卡;酷狗音乐会员月卡;蜻蜓FM会员月卡;游戏礼包:斗鱼直播15000鱼丸;贪吃蛇大作战游戏礼包;迷你世界150迷你币;使命召唤游戏礼包;英雄联盟手游游戏礼包;QQ飞车游戏礼包;穿越火线游戏礼包;腾讯福利鹅白银会员。可领会员会根据各平台运营情况调整，同一款权益每月仅可领取一次)。
2、生效规则:订购成功立即扣费、立即生效，次月自动续订，从话费中扣取产品费用。
3、权益规则:扣费后权益立即生效。通用流量、云加速(专业版)权益直充至订购手机号码中，热门权益需用户手动领取。领取后会员有效期30天。
4、适用用户:本业务仅限联通手机用户订购。处于紧急停机(挂失)、欠费停机、停机保号等状态的后付费用户及处于未激活期充值期、锁定期的预付费用户无法订购本业务。达量限速套餐用户订购本业务的，不提升原套餐限速阈值和网速，不能做为限速后解限的加速包使用，依然执行基础套餐限速阈值的约定。
5、互斥关系:单个产品不可重复订购;同一个手机号码已成功订购云加速(游戏版)，再成功订购云加速(专业版)，云加速会员服务会升级覆盖成云加速(专业版)手机号码同时订购多个云加速系列产品，仅能到账云加速会员服务一次，不能叠加;
6、产品内可领权益及权益兑换方式会根据合作方变动进行调整具体以权益领取页面展示权益为准。
7、退订说明
(1)退订路径:如需退订，请登录【中国联通】app-服务-订购业务-退订-确认提交。
(2)生效时间:退订次月生效。申请退订后，当月可继续享受包内包含的云加速(专业版)及通用流量包服务，有效期至当月底。申请退订期间，请保证号码状态正常、畅通，否则将导致退订失败。
8、如有任何疑问，请拨打客服热线电话4001189599。
9、产品服务提供方:联通在线上海公司。
二、权益使用说明
1、通用流量、云加速(专业版)权益直充至订购手机号码中;2款热门权益需用户手动每月领取。
2、领取路径:“联通加速”微信公众号菜单栏-【权益领取】【我的云加速】
3、权益到账时间:首月订购成功用户立即可领取权益，权益领取时需保证号码状态正常、保持本业务在订状态。
4、云加速(专业版)规则
(1)“云加速(专业版)”是中国联通推出的网络加速创新产品包含手机性能诊断，手机终端一键清理，双通道加速，游戏加速服务，应用加速服务。
(2)订购成功后，提供服务自动开通，无需领取;用户需前往联通加速APP，输入订购手机号后登录，点击个人中心“我的”即可查看服务开通情况。登录后云加速(专业版)产品服务自动激活解锁。
(3)加速功能仅限订购号码可享受，订购手机号码漫游至境外及台港澳地区均不能进行加速操作。用户需使用联通加速APP打开需加速的应用或游戏方可实现加速。加速范围以联通加速APP内产品规则为准。
(4)如需获得最佳加速效果请同时开启手机的Wi-Fi和移动数据,加速状态下开启手机wi-Fi同样消耗移动数据。
(5)加速状态下定向流量包不可用。
(6)用户可微信搜索并关注公众号【联通加速】，点击【加速专区】-【联通加速下载】跳转下载页面，选择相应版本下载安装;i0S用户可至苹果商店搜索“联通加速”后下载安装;安卓用户可至各大应用商店(oppo，vivo，360等)搜索“联通加速”后下载安装;
(7)手机终端一键清理等功能需要用户授权相应的访问权限方可使用。加速范围以联通激素APP内支持游戏及应用为准。
5、流量权益说明
(1)使用顺序:定向流量->云加速(专业版)通用流量包25元->套内流量。
(2)共享规则:流量不可转结，不可共享。超出流量按号码主套餐资费执行，须服从流量封顶统一限制。
(3)流量使用范围:本产品包含的国内流量指大陆地区通用流量不含港澳台地区流量。
(4)查询路径:【中国联通APP】-服务-余量查询。
6、热门权益详细规则
1、“云加速(专业版)通用流量包25元”系列产品每月礼包领取时需保持该业务产品在订状态，已退订成功用户视为自动放弃领取资格，请谨慎退订。
2、如无特殊说明，每月礼包均仅能领取至已订购“云加速(专业版)通用流量包25元”产品的用户手机号码中，领取需保证领取号码正常使用，用户号码异常、停机等情况可能会导致领取失败。
3、每月礼包限产品在订用户每月从“每月可选礼包”中选择2款不同礼包各领取1次，领取后无法退还，当月领取完毕后，次月方能重新选择领取，请谨慎领取。
4、当月未选择每月礼包领取的用户视为自动放弃领取资格，不可补领;已领取礼包仅当月有效，:请领取后尽快至对应产品中使用。
(1)领取以下VIP月卡成功后，将直充到您开通会员的手机号码中，请使用该手机号到指定APP上登录享特权:爱奇艺;优酷视频;腾讯视频;:芒果TV;QQ音乐;网易云音乐;酷狗音乐;蜻蜓FM、斗鱼。
(2)领取以下礼包时手机号只能绑定当前登录的游戏账号(QQ号或微信号)，且微信、QQ账号不通用，微信账号1与微信账号2不通用，QQ账号1与QQ账号2不通用:使命召唤游戏礼包(已订购过《使命召唤(手游)》中国联通免费特权流量包手机号码用户可能领取失败);英雄联盟手游游戏礼包(已订购过《英雄联盟(手游)》中国联通免费特权流量包手机号码用户可能领取失败)飞车游戏礼包(巳订购过(手游)》中国联K车通免费特权流量包手机号码用户可能领取失败);穿越火线游戏礼包(已订购过《穿越火线(手游)》中国联通免费特权流量包手机号码用户可能领取失败)
(3)贪吃蛇大作战战斗礼包:“贪吃蛇大作战”手机客户端游戏道具:苹果*1600、复活卡*50、玫瑰*30。
(4)迷你世界150迷你币礼包:“迷你世界”手机客户端游戏道具:150迷你币;(已订购“迷你世界会员流量包“业务手机号码用户可能领取该礼包失败;1个迷你号仅能领取该类型赠送的迷你币最多3次)。
(5)腾讯福利鹅白银会员礼包:每月可在腾讯福利鹅会员白银礼包系列中选择1款权益生效，包含白银专属礼包、白银活跃礼包;白银专属礼包一期可选范围:
(1)穿越火线手游礼包:白银专属礼包包含100钻石、瞳挑战卡*1
(2)使命召唤手游礼包:100cp、金币*188及高级武器经验卡*1
(3)英雄联盟手游礼包:联盟手游币*100、皮肤体验卡*1天、组队加成卡*1;
(4)欢乐斗地主APP礼包:132000欢乐豆。白银活跃礼包领取资格需满足对应APP内活动，可领取白银活跃礼包(礼包名称根据活动版本调整，内容可至微信搜索小程序【腾讯福利鹅】查看)。`